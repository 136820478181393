import {GalleryApp} from '../../../gallery/components/GalleryApp/GalleryApp';
import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {getWrappedComponent} from '../../../appConfig';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';

export default getWrappedComponent({
  Component: GalleryApp,
  withStyleStrategy: withStyles,
  withExperimentAttribute: 'useYoshiStyleParamStandard',
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
