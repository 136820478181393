/* istanbul ignore file */

import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import {createStylesParam, createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {
  Alignments,
  ButtonWidthType,
  ContentJustification,
  GridType,
  HoverType,
  ImageCarouselArrowSize,
  ImageModeId,
  ImagePlacements,
  ImagePositions,
  ImageRatioId,
  LoadMoreType,
  PaginationType,
  RibbonPlacementId,
  RibbonType,
  VerticalAlignments,
} from '../../types/galleryTypes';
import {DEFAULT_AUTO_GRID_PRODUCTS_COUNT, DEFAULT_PRODUCT_IMAGE_SIZE, MAX_ROWS} from '../../constants';

type IStylesParams = {
  full_width: StyleParamType.Boolean;
  showAlternativeImage: StyleParamType.Boolean;
  galleryFiltersCategories: StyleParamType.Boolean;
  galleryFiltersPrice: StyleParamType.Boolean;
  galleryFiltersProductOptions: StyleParamType.Boolean;
  galleryShowFilters: StyleParamType.Boolean;
  galleryShowSort: StyleParamType.Boolean;
  gallerySortNameAsc: StyleParamType.Boolean;
  gallerySortNameDes: StyleParamType.Boolean;
  gallerySortNewest: StyleParamType.Boolean;
  gallerySortPriceAsc: StyleParamType.Boolean;
  gallerySortPriceDes: StyleParamType.Boolean;
  gallery_showAddToCartButton: StyleParamType.Boolean;
  gallery_addToCartButtonShowOnHover: StyleParamType.Boolean;
  gallery_alternateImagePosition: StyleParamType.Boolean;
  gallery_showAddToCartQuantity: StyleParamType.Boolean;
  gallery_showDividers: StyleParamType.Boolean;
  gallery_showPrice: StyleParamType.Boolean;
  gallery_showRibbon: StyleParamType.Boolean;
  gallery_showProductName: StyleParamType.Boolean;
  gallery_showTitle: StyleParamType.Boolean;
  gallery_paginationFirstLastArrows: StyleParamType.Boolean;
  gallery_showProductOptionsButton: StyleParamType.Boolean;
  showQuickView: StyleParamType.Boolean;
  responsive: StyleParamType.Boolean;

  galleryColumns: StyleParamType.Number;
  galleryRows: StyleParamType.Number;
  galleryMargin: StyleParamType.Number;
  galleryMarginRow: StyleParamType.Number;
  galleryMarginColumn: StyleParamType.Number;
  'mobile:galleryMarginRow': StyleParamType.Number;
  'mobile:galleryMarginColumn': StyleParamType.Number;
  gallery_productMargin: StyleParamType.Number;
  'mobile:gallery_productMargin': StyleParamType.Number;
  gallery_imageMode: StyleParamType.Number;
  'mobile:gallery_imageMode': StyleParamType.Number;
  gallery_ribbonPlacement: StyleParamType.Number;
  gallery_ribbonType: StyleParamType.Number;
  galleryImageRatio: StyleParamType.Number;
  'mobile:galleryImageRatio': StyleParamType.Number;
  'mobile:galleryMargin': StyleParamType.Number;
  'mobile:galleryColumns': StyleParamType.Number;
  gallery_addToCartAction: StyleParamType.Number;
  gallery_productSize: StyleParamType.Number;
  gallery_productsCount: StyleParamType.Number;
  gallery_gapSize: StyleParamType.Number;
  gallery_gapSizeColumn: StyleParamType.Number;
  gallery_gapSizeRow: StyleParamType.Number;
  gallery_gridType: StyleParamType.Number;
  gallery_loadMoreProductsType: StyleParamType.Number;
  gallery_paginationFormat: StyleParamType.Number;
  'mobile:gallery_productNameFontSize': StyleParamType.Number;
  'mobile:gallery_productPriceFontSize': StyleParamType.Number;
  'mobile:gallery_buttonTextFontSize': StyleParamType.Number;
  'mobile:gallery_ribbonTextFontSize': StyleParamType.Number;
  gallery_imageWidth: StyleParamType.Number;
  'mobile:gallery_imageWidth': StyleParamType.Number;
  gallery_imageAndInfoSpacing: StyleParamType.Number;
  'mobile:gallery_imageAndInfoSpacing': StyleParamType.Number;

  gallery_hoverType: StyleParamType.Font;
  gallery_imageCarouselArrowSize: StyleParamType.Font;
  gallery_paginationAlignment: StyleParamType.Font;
  gallery_verticalAlignment: StyleParamType.Font;
  gallery_imagePlacement: StyleParamType.Font;
  gallery_imagePosition: StyleParamType.Font;
  gallery_alignment: StyleParamType.Font;
  gallery_addToCartButtonWidth: StyleParamType.Font;
};

const responsive = createStylesParam('responsive', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({isEditorX}) => isEditorX,
});

const showAlternativeImage = createStylesParam('showAlternativeImage', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

const galleryColumns = createStylesParam('galleryColumns', {
  type: StyleParamType.Number,
  getDefaultValue: ({dimensions}) => {
    if (!dimensions) {
      return;
    }

    if (typeof dimensions.width !== 'number') {
      return 4;
    }
    const componentWidth: number = dimensions.width;
    const PRODUCT_ITEM_MIN_WIDTH: number = 250;
    const defaultColumns = Math.round(componentWidth / PRODUCT_ITEM_MIN_WIDTH);
    return Math.min(defaultColumns, 4);
  },
});

const galleryMargin = createStylesParam('galleryMargin', {
  type: StyleParamType.Number,
  getDefaultValue: () => 10,
});

const gallery_productMargin = createStylesParam('gallery_productMargin', {
  type: StyleParamType.Number,
});

const gallery_gapSize = createStylesParam('gallery_gapSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
});

const gallery_imageMode = createStylesParam('gallery_imageMode', {
  type: StyleParamType.Number,
  getDefaultValue: () => ImageModeId.Crop,
});

const galleryImageRatio = createStylesParam('galleryImageRatio', {
  type: StyleParamType.Number,
  getDefaultValue: () => ImageRatioId._1x1,
});

const gallery_imageWidth = createStylesParam('gallery_imageWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 50,
});

const gallery_imageAndInfoSpacing = createStylesParam('gallery_imageAndInfoSpacing', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

const gallery_titleFontStyle = createStylesParam('gallery_titleFontStyle', {
  type: StyleParamType.Font,
});

const gallery_priceFontStyle = createStylesParam('gallery_priceFontStyle', {
  type: StyleParamType.Font,
});

const gallery_addToCartButtonTextFont = createStylesParam('gallery_addToCartButtonTextFont', {
  type: StyleParamType.Font,
});

const gallery_ribbonTextFont = createStylesParam('gallery_ribbonTextFont', {
  type: StyleParamType.Font,
});

export default createStylesParams<IStylesParams>({
  full_width: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showAlternativeImage,
  galleryFiltersCategories: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryFiltersPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryFiltersProductOptions: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  galleryShowSort: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallerySortNameAsc: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortNameDes: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortNewest: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortPriceAsc: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallerySortPriceDes: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showAddToCartButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_addToCartButtonShowOnHover: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_alternateImagePosition: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showAddToCartQuantity: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showProductName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  gallery_paginationFirstLastArrows: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showProductOptionsButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showQuickView: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  responsive,
  galleryColumns,
  galleryRows: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => {
      const columnsNumber = getStyleParamValue(galleryColumns);
      if (!columnsNumber) {
        return;
      }
      const LEGACY_MAX_ITEM_PER_PAGE = 20;
      return Math.min(Math.floor(LEGACY_MAX_ITEM_PER_PAGE / columnsNumber), MAX_ROWS / 2);
    },
  },
  galleryMargin,
  galleryMarginRow: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryMargin),
  },
  galleryMarginColumn: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryMargin),
  },
  'mobile:galleryMarginRow': {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  'mobile:galleryMarginColumn': {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gallery_productMargin,
  'mobile:gallery_productMargin': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_productMargin) ?? 0,
  },
  gallery_imageMode,
  'mobile:gallery_imageMode': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageMode),
  },
  gallery_ribbonPlacement: {
    type: StyleParamType.Number,
    getDefaultValue: () => RibbonPlacementId.OnImage,
  },
  gallery_ribbonType: {
    type: StyleParamType.Number,
    getDefaultValue: () => RibbonType.RECTANGLE,
  },
  galleryImageRatio,
  'mobile:galleryImageRatio': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(galleryImageRatio),
  },
  'mobile:galleryMargin': {
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  'mobile:galleryColumns': {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  gallery_addToCartAction: {
    type: StyleParamType.Number,
    getDefaultValue: () => AddToCartActionOption.MINI_CART,
  },
  gallery_productSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_PRODUCT_IMAGE_SIZE,
  },
  gallery_productsCount: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_AUTO_GRID_PRODUCTS_COUNT,
  },
  gallery_gapSize,
  gallery_gapSizeColumn: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_gapSize),
  },
  gallery_gapSizeRow: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_gapSize),
  },
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => (getStyleParamValue(responsive) ? GridType.AUTO : GridType.MANUAL),
  },
  gallery_loadMoreProductsType: {
    type: StyleParamType.Number,
    getDefaultValue: () => LoadMoreType.BUTTON,
  },
  gallery_paginationFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => PaginationType.PAGES,
  },
  'mobile:gallery_productNameFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_titleFontStyle)?.size ?? 16,
  },
  'mobile:gallery_productPriceFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_priceFontStyle)?.size ?? 13,
  },
  'mobile:gallery_buttonTextFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_addToCartButtonTextFont)?.size ?? 15,
  },
  'mobile:gallery_ribbonTextFontSize': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_ribbonTextFont)?.size ?? 14,
  },
  gallery_imageWidth,
  'mobile:gallery_imageWidth': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageWidth),
  },
  gallery_imageAndInfoSpacing,
  'mobile:gallery_imageAndInfoSpacing': {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(gallery_imageAndInfoSpacing),
  },

  gallery_hoverType: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => ({
      fontStyleParam: false,
      value: getStyleParamValue(showAlternativeImage) ? HoverType.Alternate : HoverType.None,
    }),
  },
  gallery_imageCarouselArrowSize: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ImageCarouselArrowSize.SMALL,
    }),
  },
  gallery_paginationAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ContentJustification.CENTER,
    }),
  },
  gallery_verticalAlignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: VerticalAlignments.CENTER,
    }),
  },
  gallery_imagePlacement: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ImagePlacements.VERTICAL,
    }),
  },
  gallery_imagePosition: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ImagePositions.LEFT,
    }),
  },
  gallery_alignment: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: Alignments.LEFT,
    }),
  },
  gallery_addToCartButtonWidth: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: ButtonWidthType.STRETCH,
    }),
  },
});
